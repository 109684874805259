import { config } from './config'

export interface IImageProxyOptions {
  height?: number
  width?: number
  quality?: number
  resize?: 'auto' | 'fit' | 'fill'
  noPrefix?: boolean
}

export function imageProxy(urlOrFilename: string, options: IImageProxyOptions = {}) {
  if (!urlOrFilename) return ''

  const { height = 0, width = 0, quality = 80, resize = 'auto', noPrefix } = options

  const _url = noPrefix ? urlOrFilename : `${config.publicRuntimeConfig.cdnBucket}/${urlOrFilename}`

  return `${config.publicRuntimeConfig.cdnUrl}/insecure/q:${quality}/rs:${resize}:${width}:${height}:1:0/plain/${_url}`
}

export default imageProxy
