import { RoleType } from '@ts/core/src/common/constants/role-type'
import { useStore } from '../store/hooks'

export const useUser = () => {
  const auth = useStore(s => s.auth)
  const user = auth?.user

  const isAdmin = user?.role === RoleType.ADMIN
  const isStoreUser = user?.role === RoleType.USER
  const isImpersonating = !!auth.impersonationToken
  const isLoggedIn = !!user

  return { isAdmin, isStoreUser, user, isImpersonating, isLoggedIn, token: auth?.token }
}
