import { config } from './config'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

export type RequestOptions<V = any> = AxiosRequestConfig & { data?: V; authorized?: boolean }

export const backend = async <T, V = any>(path: string, options?: RequestOptions<V>) => {
  let token

  // console.log('backend')

  // if (options?.authorized) {
  //   token = store.getState().auth.token
  // }

  const { data }: AxiosResponse<T> = await axios(path, {
    baseURL: config.publicRuntimeConfig.backendRoot,
    headers: {
      ...options?.headers,
      ...(!!token && { Authorization: `Bearer ${token}` }),
    },
    ...options,
  })

  return data
}

export const humpclub = async <T, V = any>(path: string, options?: RequestOptions<V>) => {
  let token

  const { data }: AxiosResponse<T> = await axios(path, {
    baseURL: config.publicRuntimeConfig.humpclubRoot || `http://localhost:4001`,
    headers: {
      ...options?.headers,
      ...(!!token && { Authorization: `Bearer ${token}` }),
    },
    ...options,
  })

  return data
}

export const query = <T>(path: string, options?: AxiosRequestConfig) => backend<T>(path, options)

export const humpclubQuery = <T>(path: string, options?: AxiosRequestConfig) =>
  humpclub<T>(path, options)

export const mutation = <R>(path: string, options?: AxiosRequestConfig) => data =>
  backend<R>(path, {
    method: 'post',
    ...options,
    data,
  })

export const humpclubMutation = <R>(path: string, options?: AxiosRequestConfig) => data =>
  humpclub<R>(path, {
    method: 'post',
    ...options,
    data,
  })
