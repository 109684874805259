import getConfig from 'next/config'
import { IconProps } from '@chakra-ui/core'

interface RouteItem {
  name: string
  path: string
  icon: IconProps['name']
}

export interface Route {
  title: string
  routes: RouteItem[]
}

export const config = {
  app: {
    name: 'FullStack',
  },
  google: {
    apiKey: 'AIzaSyD70o12WpgtDR13iV3fQWBURpmWhkEZmzY',
  },
  imgProxy: {
    url: 'https://cdn.thirsty-camel.pipelabs.dev',
  },
  ...getConfig(),
}

console.log(config)
