import { InputGroup } from './../../../../ui/lib/components/InputGroup/InputGroup'
import type { UserRegisterDto } from '@ts/core/src/modules/auth/dto/UserRegisterDto'
import type { UserDto } from '@ts/core/src/modules/user/dto/UserDto'

import { Thunk, thunk, Action, action, persist } from 'easy-peasy'
import { backend } from '../../utils/backend'
import { UserLoginDto } from '@ts/core/src/modules/auth/dto/UserLoginDto'
import { LoginPayloadDto } from '@ts/core/src/modules/auth/dto/LoginPayloadDto'
import { TokenPayloadDto } from '@ts/core/src/modules/auth/dto/TokenPayloadDto'
import type { UserEntity } from '@ts/core/src/modules/user/user.entity'
import { setCookie, destroyCookie } from 'nookies'

export const sendMessage = (type: string, data?: any) => {
  const isServer = typeof window === 'undefined'

  //@ts-ignore
  if (!isServer && window && window.ReactNativeWebView) {
    //@ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }))
  }
}

export interface AuthModel {
  user?: UserEntity
  token?: string
  impersonationToken?: string | null
  isFetching: boolean
  error: Error | null
  setStatus: Action<AuthModel, { isFetching: boolean; error?: Error }>
  setUser: Action<AuthModel, UserEntity>
  getUser: Thunk<AuthModel>
  setToken: Action<AuthModel, TokenPayloadDto>
  setImpersonationToken: Action<AuthModel, string | null>
  stopImpersonating: Thunk<AuthModel>
  login: Thunk<AuthModel, UserLoginDto, any, {}, Promise<LoginPayloadDto>>
  logout: Action<AuthModel>
}

const auth: AuthModel = {
  token: null,
  impersonationToken: null,
  user: null,
  isFetching: false,
  error: null,
  setStatus: action((state, { isFetching, error }) => {
    state.isFetching = isFetching
    state.error = error
  }),
  setUser: action((state, user) => {
    state.user = user
  }),
  setToken: action((state, token) => {
    state.token = token.accessToken
    setCookie(null, 'portal_token', token.accessToken, { path: '/' })
    sendMessage('PORTAL_LOGIN', token.accessToken)
  }),
  setImpersonationToken: action((state, impersonationToken) => {
    state.impersonationToken = impersonationToken
    if (impersonationToken) {
      setCookie(null, 'impersonation_token', impersonationToken, { path: '/' })
    } else {
      destroyCookie(null, 'impersonation_token', { path: '/' })
    }
  }),
  stopImpersonating: thunk(async actions => {
    actions.setImpersonationToken(null)
    await actions.getUser()
  }),
  logout: action(state => {
    state.user = null
    state.token = null
    state.isFetching = false
    state.error = null
    destroyCookie(null, 'portal_token', { path: '/' })
    sendMessage('PORTAL_LOGOUT')
  }),
  getUser: thunk(async (actions, __, helpers) => {
    const state = helpers.getState()
    const token = state.impersonationToken || state.token

    console.warn({ token })

    const user = await backend<UserEntity>('auth/me', {
      headers: { Authorization: `Bearer ${token}` },
    })
    actions.setUser(user)
  }),
  login: thunk(async (actions, loginReq) => {
    actions.setStatus({ isFetching: true })
    let response: LoginPayloadDto
    try {
      response = await backend<LoginPayloadDto, UserLoginDto>('auth/login', {
        data: loginReq,
        method: 'post',
      })
      actions.setStatus({ isFetching: false })
      actions.setUser(response.user)
      actions.setToken(response.token)
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
      return null
    }

    return response
  }),
}

export default auth
